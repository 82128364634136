import { REQUIRED_FIELD, INVALID_FIELD } from '@/utils/validationMessagesUtils'

export default {
  codErrors () {
    const errors = []
    if (!this.$v.item.cod.$dirty) return errors
    !this.$v.item.cod.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  nameErrors () {
    const errors = []
    if (!this.$v.item.name.$dirty) return errors
    !this.$v.item.name.required && errors.push(REQUIRED_FIELD)
    return errors
  },
  
  birthErrors () {
    const errors = []
    if (!this.$v.item.birth.$dirty) return errors
    !this.$v.item.birth.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.birth.minLength && errors.push(INVALID_FIELD)
    return errors
  },
  
  emailErrors () {
    const errors = []
    if (!this.$v.item.email.$dirty) return errors
    !this.$v.item.email.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.email.email && errors.push('E-mail inválido')
    return errors
  },

  documentErrors () {
    const errors = []
    if (!this.$v.item.document.$dirty) return errors
    !this.$v.item.document.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.document.isValidCpfOrCnpj && errors.push(INVALID_FIELD)
    return errors
  },

  rgErrors () {
    const errors = []
    if (!this.$v.item.rg.$dirty) return errors
    !this.$v.item.rg.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  phoneErrors () {
    const errors = []
    if (!this.$v.item.phone.$dirty) return errors
    !this.$v.item.phone.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.phone.minLength && errors.push(INVALID_FIELD)
    !this.$v.item.phone.maxLength && errors.push(INVALID_FIELD)
    !this.$v.item.phone.isValidPhone && errors.push('Telefone inválido')
    !this.$v.item.phone.validateDDD && errors.push('DDD Inválido')
    return errors
  },

  genderErrors () {
    const errors = []
    if (!this.$v.item.gender.$dirty) return errors
    !this.$v.item.gender.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  maritalStatusErrors () {
    const errors = []
    if (!this.$v.item.maritalStatus.$dirty) return errors
    !this.$v.item.maritalStatus.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  nameSpouseErrors () {
    const errors = []
    if (!this.$v.item.nameSpouse.$dirty) return errors
    !this.$v.item.nameSpouse.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  birthSpouseErrors () {
    const errors = []
    if (!this.$v.item.birthSpouse.$dirty) return errors
    !this.$v.item.birthSpouse.required && errors.push(REQUIRED_FIELD)
    return errors
  }
}
