<template>
  <v-form class="box-form">
     <v-card-text class="modal-cadastro-form">
      <v-row v-for="(it, key, index) in item.adresses" :key="index">

        <v-col cols="12" class="modal-cadastro-form-col-imput" v-if="exibirTitle(item.adresses)">
        <v-card-title>
          Endereço {{ index + 1 }}

          <v-btn
            fab dark 
            color="red" 
            class="fab-remove-item ml-1"
            @click="removeItem(key)"
          >
            <v-icon dark size="10">mdi-minus</v-icon>
          </v-btn>
        </v-card-title>
        </v-col>
        
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-select
            :items="listaTipoEndereco"
            v-model="it.typeAddress"
            dense
            outlined
            label="Tipo de endereço:"
            :error-messages="errorMensagens(key, 'typeAddress')"
          />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-select
            :items="listaSituacoesEndereco"
            v-model="it.typeSituation"
            dense
            outlined
            label="Situação:"
          />
        </v-col>


        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field 
            type="tel"
            v-model="it.cep"
            outlined
            dense
            @keyup="buscarCep(it)"
            label="CEP:"
            v-mask="['#####-###']"
            class="imput-cep"
            :error-messages="errorMensagens(key, 'cep')"
            >
              <template v-slot:append-outer>
                <v-btn
                  depressed 
                  tile
                  :color="variables.colorPrimary"
                  class="ma-0 btn-cep"
                  :maxWidth="'40'"
                  :minWidth="'40'"
                  @click="buscarCep(it)"
                  :loading="loadingCep"
                >
                    <svg fill="#FFF" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="20px" height="20px"><path d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z"/></svg>
                </v-btn>
              </template>
            </v-text-field>
        </v-col>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field
            v-model="it.address"
            label="Endereço:"
            outlined
            dense
            :disabled="it.cepValidate"
            :error-messages="errorMensagens(key, 'address')" />
          </v-col>
        <!-- <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionCityErrors" v-model="item.city" label="Cidade:" @input="$v.item.city.$touch()" @blur="$v.item.city.$touch()" /></v-col>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionStateErrors" v-model="item.state" label="Estado:" @input="$v.item.state.$touch()" @blur="$v.item.state.$touch()" /></v-col> -->

          
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field 
            v-model="it.neighborhood"
            label="Bairro:"
            outlined
            dense
            :disabled="it.cepValidate"
            :error-messages="errorMensagens(key, 'neighborhood')" />
        </v-col>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field type="tel"
            v-model="it.number"
            label="Número:"
            outlined
            dense
          />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="12">
          <v-text-field
            v-model="it.complement"
            label="Complemento:"
            outlined
            dense
          />
        </v-col>

        <!-- componente de select de Estado e cidade -->
        <searchUfCity
          @addUf="it.state = arguments[0]"
          @addCity="it.city = arguments[0]"
          :propDense="true"
          :propOutlined="true"
          :propLabelUF="'UF'"
          :propUf="it.state"
          :propCity="it.city"
          :propDisabled="it.cepValidate"
          :errorMessagesCity="errorMensagens(index, 'state')"
          :errorMessagesState="errorMensagens(index, 'city')"
          />

        <!-- <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field v-model="item.lat" label="Latitude" type="number"/></v-col>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field v-model="item.lng" label="Longitude" type="number"/></v-col> -->
        <!-- <v-col cols="12" class="modal-cadastro-form-col-imput" sm="12"><v-text-field v-model="item.complement" label="Complemento:" /></v-col> -->
      </v-row>
    </v-card-text>

    <v-card-actions class="modal-cadastro-footer">
      <v-btn 
        :color="variables.colorPrimary"
        @click="addItem"
        class="br-btn br-btn-cancelar">Adicionar outro endereço</v-btn>
      <v-btn
        @click="proximaEtapa"
        :color="variables.colorPrimary"
        class="br-btn">Avançar</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
// eslint-disable-next-line
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
// import Events from '@/core/service/events'
// eslint-disable-next-line
import { isOnlyNumber, isValidCpfOrCnpj } from '@/utils/validationUtils'
// import { validCnpj } from '@/core/service/utils'
// import { cnpj } from 'cpf-cnpj-validator'
import variables from '@/assets/styles/helpers/_variables.scss'
// eslint-disable-next-line
import { size, compact, map, flatMap } from 'lodash'
// import moment from 'moment'
// import { errorSnackbar } from '@/core/service/utils'
import axios from 'axios'
import config from '@/core/config'

import searchUfCity from '@/views/components/searchUfCity'

/**
 * Mixins
 */
// import autoComplete from '@/views/cadastros/entities/mixins/autoComplete'
import modalCadastro from '@/views/cadastros/entities/mixins/modalCadastro'

export default {
  name: 'ModalCadastroEstabelecimento',
  mixins: [validationMixin, modalCadastro],
  directives: {mask},
  components: {
    searchUfCity
  },
  props: {
    touch:  {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    menuDate: false,
    loadingCep: false,
    menuDateSpouse: false,
    dtBirthString: '',
    dtCadastroString: '',
    dtSpouseString: '',
    activeModal: false,
    loading: false,
    listaTipoEndereco: [
      { text: 'Casa', value: 'house' },
      { text: 'Apartamento', value: 'apartment' },
      { text: 'Comercial', value: 'commercial' }
    ],
    listaGeneros: [
      { text: 'Masculino', value: 'M' },
      { text: 'Feminino', value: 'F' }
    ],

    listaSituacoesEndereco: [
      { text: 'Próprio', value: 'own' },
      { text: 'Alugado', value: 'rented' },
      { text: 'Outro', value: 'other' },
    ],

    qtdItemForm: 1,

    form: {
      item1: {
        typeAddress: 'commercial',
        typeSituation: '',
        cep: '',
        address: '',
        neighborhood: '',
        number: '',
        state: '',
        city: '',
        cepValidate: false,
        complement: ''
      }
    }
  }),
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('estabelecimento', {
      item: 'item',
      listaItensEstabelecimento: 'listaItensSelect',
      listaItensCredenciador: 'listaItensSelectCredenciador'
    }),

    variables: () => variables,
  },

   watch: {
    'touch' (val) {
      if (val)  this.$v.item.$touch()
    }
  },


  mounted () {
    // Events.$on('cadastro::closeModal', () => {
    //   this.qtdItemForm = 1,

    //   this.form = {
    //     item1: {
    //       typeAddress: '',
    //       typeSituation: '',
    //       cep: '',
    //       address: '',
    //       neighborhood: '',
    //       number: '',
    //       state: '',
    //       city: '',
    //       cepValidate: false
    //     }
    //   }
    // })

    // if (this.item.id) {
    //   this.item.adresses.forEach((it, idx) => {
    //     this.form[`item${idx+1}`] = {
    //       typeAddress: it.typeAddress,
    //       typeSituation: it.typeSituation,
    //       cep: it.cep,
    //       address: it.address,
    //       neighborhood: it.neighborhood,
    //       number: it.number,
    //       state: it.state,
    //       city: it.city,
    //       cepValidate: false
    //     }
    //   })
    // }

    this.qtdItemForm = size(this.item.adresses)

    if (this.item.id) {
      this.$v.item.adresses.$touch()
    }
  },
  methods: {
    ...mapActions('estabelecimento', ['cadastrarItem', 'limparItem', 'getItens', 'editarItem', 'obterItensSelect', 'obterItensSelectProducts', 'obterEntityProduct', 'obterItensSelect', 'getItem']),
    ...mapActions('estabelecimentoComissao', {
      obterItensSelectParentId: 'obterItensSelect',
      clearDadosComissaoCredenciador: 'clearDados'
    }),
    // openModal () {
    //   this.activeModal = true
    // },

    openModal () {
      this.activeModal = true
    },

    async buscarCep (it) {
      const vm = this
      if(/^[0-9]{5}-[0-9]{3}$/.test(it.cep)){
        vm.loadingCep = true
        const result = await axios.get(config.apiViaCep + it.cep + "/json/")
        if (result.data.erro) {
          vm.loadingCep = false
          it.cepValidate = false
          return
        }

        it.cepValidate = true
        it.address = result.data.logradouro
        it.state = result.data.uf
        it.city = result.data.localidade
        it.neighborhood = result.data.bairro

        vm.loadingCep = false
      }
    },

    addItem () {
      this.qtdItemForm++
      this.$set(this.item.adresses, `item${this.qtdItemForm}`,
        {
          typeAddress: 'commercial',
          typeSituation: '',
          cep: '',
          address: '',
          neighborhood: '',
          number: '',
          state: '',
          city: '',
          cepValidate: false
        }
      )
    },

    exibirTitle (form) {
      return size(form) > 1 ? true : false
    },

    removeItem(item) {
      this.$delete(this.item.adresses, item)
    },

    errorMensagens (index, item) {
      let error = []
      if (!this.$v.item.adresses[index]) return []
      if (!this.$v.item.adresses[index][item].$dirty) return []

      !this.$v.item.adresses[index][item].required && error.push('Campo Obrigatório')
      return error
    },


    proximaEtapa () {
      if (this.$v.item.adresses.$invalid) {
        this.$v.item.adresses.$touch()
        document.querySelector('.v-dialog').scroll({ top: 0, left: 0, behavior: 'smooth' })
        return false
      }

      this.$emit('proximoCadastro', { validacao: this.$v.item.adresses })
    }

  },

  validations () {
    let items = {}
    let obj = {}
    obj = flatMap(this.item.adresses, (v, k) => {
      return { 
        [k]: {
          address: {required},
          cep: {required},
          cepValidate: {required},
          city: {required},
          neighborhood: {required},
          // number: {required},
          state: {required},
          typeAddress: {required},
          // typeSituation: {required},
        }
      }
    })
    obj.forEach(v => {
      items[Object.keys(v)[0]] = v[Object.keys(v)[0]]
    })

    return { 
      item: {
        adresses:  items 
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './style.scss';
  .modal-cadastro-form-col-imput .v-text-field--outlined.error--text::v-deep {
    .v-input__slot {
      margin-bottom: 20px !important;
    }
    .v-text-field__details {
      bottom: 5px;
    }
  }

  .modal-cadastro2 {
    .br-btn {
      width: 100%;
      max-width: 250px;
      margin: 0 !important;

      &-cancelar {
        margin-bottom: 10px !important;
      }
    }

    > .v-card .modal-cadastro-footer {
      flex-direction: column;
    }
  }

  .fab-remove-item {
    width: 20px !important;
    height: 20px !important;
    min-height: 20px !important;
    min-width: 20px !important;
  }

  .imput-cep::v-deep {
    .v-input__append-outer {
      margin-top: 0 !important;
      margin-left: 0 !important;
    }

    .v-btn {
      height: 40px !important;
      border-radius: 0px 4px 4px 0px;
      // background: #d3d3d3 !important;
      // color: #000 !important;
    }
  }
</style>