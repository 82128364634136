<template>
  <Modal
    :activeModal="isActiveModal"
    @closeModal="closeModal"
    :widthModal="750"
  >

    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          {{ data.description }}
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-card
        class="white--text align-end pa-2"
      >
       <img  :src="data.image" style="max-width: 100%" />
      </v-card>
    </v-card>
  </Modal>
</template>

<script>
// eslint-disable-next-line
import { mapActions, mapGetters } from 'vuex'
// import Events from '@/core/service/events'
// eslint-disable-next-line
import { required, minValue } from 'vuelidate/lib/validators'
import variables from '@/assets/styles/helpers/_variables.scss'
// eslint-disable-next-line
import { clone, filter, size, cloneDeep } from 'lodash'
// eslint-disable-next-line
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import { mask } from 'vue-the-mask'
export default {
  name: 'ModalConfirmationPaymentPix',
  directives: { mask },
  props: {
    isActiveModal: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: (() => {})
    }
  },
  components:{
    Modal: () => import('@/views/components/modal')
  },
  data: () => ({
    modelSlide: null,
    loading: false,
    menuDateInicial: false,
    dtInicial: '',
    activeModal: false,
    input: '',
    file: null,
    promotor: {},
    loadingBtn: false,
    qtdItemForm: 1,
    dataAnexo: {},
    form: {
      item1: {
        anexo: '',
        description: '',
        name: ''
      }
    }
  }),
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    variables: () => variables,
  },
  mounted () {
  },
  watch: {
  },
  methods: {
    ...mapActions('despacho', ['confirmationPayment']),

    closeModal () {
      this.$emit('closeModal', false)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/components/modal-cadastro';
.modal-cadastro-form-col-input .auto {
  background: red;
}
.theme--light.v-input input, .theme--light.v-input textarea{
  padding: 2;
}
.theme--light.v-input{
  padding: 0;
  margin: 0;
}
#bug{
  margin-top: 7px !important
}
</style>
