<template>
  <div class="box-form box-form-products">
    <v-form>
      
      <v-card-text class="modal-cadastro-form">
        <v-card-title class="d-flex justify-space-between align-center mb-3">
          Agência: {{ entity.name }}

          <v-chip
            label
            dark
            :color="variables.colorGreenDarken"
          >
            <v-progress-circular
              indeterminate
              size="20"
              v-if="loadingTable"
            />
            <span v-else>Limite Agência: {{ totalLimite | currency }}</span>
          </v-chip>
        </v-card-title>
        <v-row>
          <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3">
            <v-select :error-messages="produtoIdErrors" :items="listProducts" label="Produtos" v-model="itemEntityProducts.productId" @input="$v.itemEntityProducts.productId.$touch()" @blur="$v.itemEntityProducts.productId.$touch()"></v-select>
          </v-col>
          <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3">
            <!-- <v-text-field type="tel" :error-messages="comissionErrors" v-model="itemEntityProducts.comission" label="Comissão"  @input="$v.itemEntityProducts.comission.$touch()" @blur="$v.itemEntityProducts.comission.$touch()" :max="100" /> -->
            <vmoney v-model="itemEntityProducts.comission" :label="'Comissão'" :options="comission" :error="comissionErrors" />
          </v-col>
          <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3">
            <!-- <v-text-field type="tel" :error-messages="limitErrors" label="Limite de Crédito" v-model.lazy="form.debtLimit" :value="form.debtLimit"  @input="$v.itemEntityProducts.debtLimit.$touch()" @blur="$v.itemEntityProducts.debtLimit.$touch()" v-money="limit"></v-text-field> -->
            <!-- <div class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted"><div class="v-input__control"><div class="v-input__slot"><div class="v-text-field__slot"><label for="input-272" class="v-label v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Comissão</label>
              <input type="text" v-model="form.debtLimit" v-money="limit" >
            </div></div><div class="v-text-field__details"><div class="v-messages theme--light"><div class="v-messages__wrapper"></div></div></div></div></div> -->
            <vmoney v-model="itemEntityProducts.debtLimit" :label="'Limite de Crédito'" :options="limit" :error="limitErrors" />
          </v-col>
            <!-- <input type="text" v-model="form.debtLimit" v-money="limit" > -->
          <v-col cols="12" class="modal-cadastro-form-col-imput d-flex justify-end" sm="3">
            <v-btn :color="variables.colorPrimary" @click="cancel" class="br-btn br-btn-cancelar br-btn-entity-products mr-2" :width="70">Cancelar</v-btn>
            <v-btn
              type="submit"
              :color="variables.colorPrimary"
              @click.prevent="submit"
              class="br-btn br-btn-entity-products"
              :loading="loading"
              :width="70"
              v-if="permiteAcao($route, 'edit') || permiteAcao($route, 'add')">{{ isEdit ? 'Editar' : 'Cadastrar' }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>

    <v-card-text class="modal-cadastro-form">
      <v-data-table
        :headers="headers"
        :items="listItensEntitiesPoducts"
        width="300px"
        height="auto"
        flat
        :items-per-page="5"
        :loading="loadingTable"
        dense
      >

        <template v-slot:item.product.name="{ item }">
          {{ item.product.name}}
        </template>

        <template v-slot:item.debtLimit="{ item }">
          {{ item.debtLimit | currency }}
        </template>

        <template v-slot:item.comission="{ item }">
          {{ item.comission | percentage }}
        </template>

        <template v-slot:item.action="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
            v-if="permiteAcao($route, 'edit')"
          >
            edit
          </v-icon>
          <v-icon
            small
            v-if="permiteAcao($route, 'delete')"
            @click="deleteItem(item)"
          >
            delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>

    <!-- <comissao :entity="entity" /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import Events from '@/core/service/events'
import { currency } from '@/core/service/utils'
// import { cnpj } from 'cpf-cnpj-validator'
import variables from '@/assets/styles/helpers/_variables.scss'
import vmoney from '@/views/components/vMoney'
import validacao from './validacaoProducts'

// const validCNPJ = (params) => {
//   console.log(params)
// }

export default {
  name: 'BrModalCadastroAts',
  mixins: [validationMixin],
  directives: {mask},
  components: {
    vmoney
    // comissao: () => import('./comissao')
  },
  props: {
    entity: {
      type: Object,
      default: () => ({
        parentId: ''
      })
    },

    loadingProdutos: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    activeModal: false,
    loading: false,
    search: '',
    headers: [
      { class: 'table-header', text: 'ID Produto', value: 'productId' },
      { class: 'table-header', text: 'Produto', value: 'product.name' },
      { class: 'table-header', text: 'Comissão', value: 'comission' },
      { class: 'table-header', text: 'Limite de Crédito', value: 'debtLimit' },
      { class: 'table-header', text: '', value: 'action', sortable: false },
    ],
    divider: '/',
    desserts: [],
    itemPerPage: [5, 10, 50, 100, {text: 'Todos', value: -1}],
    comission: {
      locale: 'pt-BR',
      prefix: '',
      suffix: ' %',
      length: 4,
      precision: 2
    },
    // limit: {
    //   prefix: 'R$ ',
    //   precision: 2,
    //   thousands: '.',
    //   decimal: ','
    // },
    limit: {
      locale: "pt-BR",
      prefix: "R$ ",
      suffix: "",
      length: 11,
      precision: 2
    },
    form: {
      comission: '',
      debtLimit: ''
    },
    loadingTable: false
  }),
  watch: {
    // 'itemEntityProducts.comission' (val) {
    //   console.log(val)
    //   if (val > 100) this.itemEntityProducts.comission = 100
    // }
    'loadingProdutos' (val) {
      this.loadingTable = val
    }
  },
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('estabelecimento', ['listProducts', 'itemEntityProducts', 'listItensEntitiesPoducts']),
    
    ...validacao,

    totalLimite () {
      return this.listItensEntitiesPoducts.reduce((a, b) => a + b.debtLimit, 0)
    },

    variables: () => variables,
    isEdit () {
      return this.itemEntityProducts.id ? !!1 : !!0
    }
  },
  mounted () {
    // this.loadingTable = true
    // this.obterItensSelectProducts().then(() => {
    //   this.loadingTable = false
    // })
    // this.obterEntityProduct({ entityId: this.entity.id })
    Events.$on('cadastro::openModalCadastro', () => {
      this.activeModal = true
    })

    Events.$on('closeModal::modalCadastro', () => {
      this.cancel()
      this.$v.itemEntityProducts.$reset()
    })
  },
  methods: {
    ...mapActions('estabelecimento', ['cadastrarEntityProduct', 'obterItensSelectProducts', 'obterEntityProduct', 'getEntityProduct', 'editEntityProduct', 'deletarItemEntityProduct', 'limparItemEntityProduct']),
    openModal () {
      this.activeModal = true
    },

    cancel () {
      this.limparItemEntityProduct()
      this.$v.itemEntityProducts.$reset()
    },

    editItem (item) {
      // this.form.comission = item.comission
      // this.form.debtLimit = 3000
      // console.log(this.form)
      this.getEntityProduct(item)
    },

    deleteItem (item) {
      this.$swal({
        icon: 'warning',
        text: `Tem certeza que deseja excluir`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.value) {
          this.deletarItemEntityProduct({id: item.id}).then(() =>{
            this.cancel()
            this.loadingTable = true
            this.obterEntityProduct({ entityId: this.entity.id })
              .finally(() => this.loadingTable = false)
            const msg = {
              toggle: true,
              type: 'success',
              msg: 'Item excluído com sucesso!'
            }
            Events.$emit('snackbarCadastro::msg', msg)
          }).catch(err => {
            const msg = {
              toggle: true,
              type: 'error',
              msg: err.error
            }
            Events.$emit('snackbarCadastro::msg', msg)
          })
        }
      })
    },

    submit () {
      if (this.$v.itemEntityProducts.$invalid) { 
        this.$v.itemEntityProducts.$touch()
        return false 
      }

      const dados = {
        entityId: this.entity.id,
        productId: this.itemEntityProducts.productId,
        comission: this.itemEntityProducts.comission,
        debtLimit: this.itemEntityProducts.debtLimit
      }

      const somaTotalLimitCreditoTable = this.listItensEntitiesPoducts
                                                                  .filter(v => v.productId !== this.itemEntityProducts.productId)
                                                                  .reduce((acc, { debtLimit }) => acc + parseFloat(debtLimit), 0)

      const somaTotalLimitCreditoTotal = somaTotalLimitCreditoTable + parseFloat(dados.debtLimit) 

      const limitTotal = 10000

      if (somaTotalLimitCreditoTotal > limitTotal) {
        this.$swal({
          icon: 'error',
          text: `A soma dos limites de crêditos não pode ultrapassar ${currency(limitTotal)}`,
          showCancelButton: false,
          showConfirmButton: false,
        })

        return false
      }
                                                                  
      this.loading = true
      // let find = this.listItensEntitiesPoducts.find(v => v.productId == this.itemEntityProducts.productId)
      // if (this.itemEntityProducts.id || find !== undefined) {
      //   dados.id = this.itemEntityProducts.id || find.id
      if (this.itemEntityProducts.id) {
        dados.id = this.itemEntityProducts.id
        
        this.editEntityProduct(dados)
        .then(() => {
          this.loadingTable = true
          this.obterEntityProduct({ entityId: this.entity.id })
            .finally(() => this.loadingTable = false)
          this.cancel()
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Edição realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
        .catch(err => {
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
        .finally(() => this.loading = false)
      } else {
        this.cadastrarEntityProduct(dados)
        .then(() => {
          this.loadingTable = true
          this.obterEntityProduct({ entityId: this.entity.id })
            .finally(() => this.loadingTable = false)
          this.cancel()
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Cadastro realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
        .catch(err => {
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
        .finally(() => this.loading = false)
      }
    },
  },

  validations () {
    return  {
      itemEntityProducts: {
        productId: { required },
        comission: { required },
        debtLimit: { required }
      }
    }
  }
}
</script>

<style lang="scss">
  @import '../../../../../assets/styles/components/formularios';
  @import '../../../../../assets/styles/components/modal-cadastro';
  .br-btn-entity-products {
    margin-top: 10px;
    min-width: auto !important;
    font-size: 0.8em !important;
  }
</style>