<template>
  <v-form class="box-form">
    <v-card-text class="modal-cadastro-form">
      <v-row>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-autocomplete
            v-model="item.parentId"
            :items="listaItensCredenciador"
            :loading="isLoadingAutoComplete"
            :search-input.sync="searchText"
            :menu-props="{'content-class': 'autocomplete-menu'}"
            @keyup="searchAutoComplete"
            item-text="text"
            item-value="value"
            :label="`Selecione um ${getEntityType(3)}`"
            class="autocomplete"
            :disabled="isEdit"
            outlined
            dense
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Buscando...
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>  {{ item.text }} </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col :cols="item.dtCreated ? 6 : 12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field 
            v-model="item.cod"
            :error-messages="codErrors"
            outlined
            label="Código Revenda:"
            type="number"
            dense
            @input="$v.item.cod.$touch()"
            @blur="$v.item.cod.$touch()" />
        </v-col>

        <v-col cols="6" class="modal-cadastro-form-col-imput" sm="6" v-if="item.dtCreated">

          <v-text-field
            outlined
            dense
            v-model="item.dtCreated"
            v-mask="['##/##/####']"
            :label="$gettext('Data Cadastro')"
            disabled>
            <template v-slot:append>
              <v-icon v-on="on">
                event
              </v-icon>
            </template>
          </v-text-field>

        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field
            v-model="item.name"
            outlined
            dense
            label="Nome Completo:"
            @input="$v.item.name.$touch()"
            @blur="$v.item.name.$touch()"
            :error-messages="nameErrors" />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <DateModal 
            :date.sync="item.birth"
            :isDateInit="item.birth ? true : false"
            :propErrorMessages="birthErrors"
            :propOutlined="true"
            :dateInit="item.birth"
            :label="$gettext('Data de Nascimento')"
          />
        </v-col>
        <v-col cols="12" class="modal-cadastro-form-col-imput " sm="6">
          <v-text-field 
            v-model="item.email"
            label="Email:"
            outlined
            dense />
        </v-col>
        <v-col cols="6" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field 
            type="tel" 
            v-model="item.document" 
            outlined
            dense
            label="CPF/CNPJ:" 
            @input="$v.item.document.$touch()" 
            @blur="$v.item.document.$touch()" 
            :error-messages="documentErrors"
            v-mask="['##.###.###/####-##', '###.###.###-##']"/>
        </v-col>

        <v-col cols="6" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field 
            type="tel" 
            outlined
            dense
            v-model="item.rg" 
            label="RG:"/>
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field 
            type="tel" 
            v-model="item.phone"
            label="Telefone Comercial:"
            outlined
            dense
            @input="$v.item.phone.$touch()"
            @blur="$v.item.phone.$touch()"
            :error-messages="phoneErrors"
            v-mask="['(##) ####-####', '(##) #####-####']"
            class="input-phone"
          >
            <template v-slot:prepend>
              <v-btn
                depressed 
                tile
                disabled
                class="ma-0 btn-phone"
                :maxWidth="'40'"
                :minWidth="'40'"
              >
                +55
              </v-btn>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="6" class="modal-cadastro-form-col-imput" sm="6">
          <v-select
            :items="listaGeneros"
            v-model="item.gender"
            dense
            outlined
            label="Gênero:"
          />
        </v-col>

        <v-col cols="6" class="modal-cadastro-form-col-imput" sm="6">
          <v-select
            :items="listaEstadosCivies"
            v-model="item.maritalStatus"
            dense
            outlined
            label="Estado Civil:"
          />
        </v-col>

        <template v-if="item.maritalStatus === 'married'">
          <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
            <v-text-field
              v-model="item.nameSpouse"
              outlined
              dense
              label="Nome do Cônjuge:"/>
          </v-col>

          <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
            <!-- <v-menu
              v-model="menuDateSpouse"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px">

              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dtSpouseString"
                  v-mask="['##/##/####']"
                  :label="$gettext('Data de Nascimento')"
                  outlined
                  dense
                  @input="testeDate(item.birthSpouse, dtSpouseString)"
                  @blur="$v.item.birthSpouse.$touch()"
                  :error-messages="birthSpouseErrors"
                  v-on="on">
                  <template v-slot:append>
                    <v-icon v-on="on">
                      event
                    </v-icon>
                  </template>
                </v-text-field>
              </template>

            <v-date-picker
              v-model="item.birthSpouse"
              no-title
              scrollable
              locale="pt-br"
              @input="closeModalDateSpousePicker(item.birthSpouse)"
              :color="variables.colorPrimary" />
            </v-menu> -->
            <DateModal 
              :date.sync="item.birthSpouse"
              :isDateInit="item.birthSpouse ? true : false"
              :propOutlined="true"
              :dateInit="item.birthSpouse"
              :label="$gettext('Data de Nascimento')"
            />
          </v-col>
        </template>



        <v-col cols="12" class="modal-cadastro-form-col-imput">
          <template v-for="(it, key, index) in item.children">
            <v-row :key="index">
              <v-col cols="12" class="pt-0">
                <v-card-title>
                  Filho (a) <span v-if="exibirQtFilhos(item.children)" class="ml-1"> - {{ index + 1 }}</span>
                  <v-btn
                    fab dark 
                    color="red" 
                    class="fab-remove-item ml-1"
                    @click="removeItem(key)"
                  >
                    <v-icon dark size="10">mdi-minus</v-icon>
                  </v-btn>
                </v-card-title>
              </v-col>

              <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                <v-text-field
                  v-model="it.name"
                  outlined
                  dense
                  label="Nome do filho(a):"/>
              </v-col>

              <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                <DateModal 
                  :date.sync="it.birth"
                  :isDateInit="it.birth ? true : false"
                  :propOutlined="true"
                  :dateInit="it.birth"
                  :label="$gettext('Data de Nascimento')"
                />
              </v-col>
            </v-row>
          </template>
        </v-col>
        
      </v-row>
    </v-card-text>

    <v-card-actions class="modal-cadastro-footer">
      <v-btn 
        :color="variables.colorPrimary"
        @click="addItem"
        class="br-btn br-btn-cancelar">Adicionar filho(a)</v-btn>
      <v-btn
        @click="proximaEtapa(2)"
        :color="variables.colorPrimary"
        class="br-btn">Avançar</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
// eslint-disable-next-line
import { required, email, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
// import Events from '@/core/service/events'
// eslint-disable-next-line
import { isOnlyNumber, isValidCpfOrCnpj, isValidPhone, validateDDD } from '@/utils/validationUtils'
// import { validCnpj } from '@/core/service/utils'
// import { cnpj } from 'cpf-cnpj-validator'
import variables from '@/assets/styles/helpers/_variables.scss'
import { size } from 'lodash'
// import moment from 'moment'
// import { errorSnackbar } from '@/core/service/utils'

import validacao from './validacaoFormCadastroPessoais'
import DateModal from '@/views/components/dateModal'

/**
 * Mixins
 */
// import autoComplete from '@/views/cadastros/entities/mixins/autoComplete'
import modalCadastro from '@/views/cadastros/entities/mixins/modalCadastro'



export default {
  name: 'ModalCadastroRevendaDadosPessoais',
  mixins: [validationMixin, modalCadastro],
  directives: {mask},
  components: {
    DateModal
  },
  props: {
    touch:  {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    menuDate: false,
    menuDateSpouse: false,
    dtBirthString: '',
    dtCadastroString: '',
    dtSpouseString: '',
    activeModal: false,
    loading: false,
    listaEstadosCivies: [
      { text: 'Solteiro (a)', value: 'single' },
      { text: 'Casado (a)', value: 'married' },
      { text: 'Divorciado (a)', value: 'divorced' },
      { text: 'Viúvo (a)', value: 'widowed' },
    ],
    listaGeneros: [
      { text: 'Masculino', value: 'M' },
      { text: 'Feminino', value: 'F' }
    ],
    qtdItemForm: 0,
  }),

  mounted () {
    if (this.item.id) {
      this.$v.item.$touch()
    }
  },

  watch: {
    'touch' (val) {
      if (val)  this.$v.item.$touch()
    }
  },

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('estabelecimento', {
      item: 'item',
      listaItensEstabelecimento: 'listaItensSelect',
      listaItensCredenciador: 'listaItensSelectCredenciador',
      qtdItemFormChildren: 'qtdItemFormChildren',
    }),
    ...validacao,

    variables: () => variables,
  },

  methods: {
    ...mapActions('estabelecimento', ['cadastrarItem', 'limparItem', 'getItens', 'editarItem', 'obterItensSelect', 'obterItensSelectProducts', 'obterEntityProduct', 'obterItensSelect', 'getItem', 'setQtdItemFormChildren']),
    ...mapActions('estabelecimentoComissao', {
      obterItensSelectParentId: 'obterItensSelect',
      clearDadosComissaoCredenciador: 'clearDados'
    }),


    addItem () {
      this.setQtdItemFormChildren()
      this.$set(this.item.children, `item${this.qtdItemFormChildren}`,
        {
          name: '',
          birth: ''
        }
      )
    },

    removeItem(item) {
      this.$delete(this.item.children, item)
    },

    exibirQtFilhos(children) {
      return size(children) > 1
    },

    proximaEtapa() {
       if (this.$v.item.$invalid) {
        this.$v.item.$touch()
        document.querySelector('.v-dialog').scroll({ top: 0, left: 0, behavior: 'smooth' })
        return false
      }

      this.$emit('proximoCadastro', { validacao: this.$v.item })
    }

  },

  validations () {
    return  {
      item: {
        cod: { required, isOnlyNumber },
        // email: { required, email },
        name: { required },
        document: { required, isValidCpfOrCnpj },
        phone: { 
          required, 
          minLength: minLength(15), 
          maxLength: maxLength(15),
          isValidPhone,
          validateDDD
        },
        birth: { required, minLength: minLength(10)},
        // rg: { required },
        // gender: { required },
        // maritalStatus: { required },
        // nameSpouse: { required: requiredIf(() => this.item.maritalStatus === 'married') },
        // birthSpouse: { required: requiredIf(() => this.item.maritalStatus === 'married') }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './style.scss';
  .modal-cadastro-form-col-imput .v-text-field--outlined.error--text::v-deep {
    .v-input__slot {
      margin-bottom: 20px !important;
    }
    .v-text-field__details {
      bottom: 5px;
    }
  }

  .modal-cadastro2 {
    .br-btn {
      width: 100%;
      max-width: 250px;
      margin: 0 !important;

      &-cancelar {
        margin-bottom: 10px !important;
      }
    }

    > .v-card .modal-cadastro-footer {
      flex-direction: column;
    }
  }

  .fab-remove-item {
    width: 20px !important;
    height: 20px !important;
    min-height: 20px !important;
    min-width: 20px !important;
  }

  .input-phone::v-deep {
    .v-input__prepend-outer {
      margin-top: 0 !important;
      margin-right: 0 !important;
    }

    .v-btn {
      height: 40px !important;
      border-radius: 4px 0 0 4px;
      background: #d3d3d3 !important;
      color: #000 !important;
    }
  }
</style>