import { REQUIRED_FIELD, ONLY_NUMBER, INVALID_FIELD } from '@/utils/validationMessagesUtils'

export default {
  institutionParentIdErrors () {
    const errors = []
    if (!this.$v.item.parentId.$dirty) return errors
    !this.$v.item.parentId.required && errors.push('Seleção do credenciador é obrigatório.')
    return errors
  },

  institutionCodErrors () {
    const errors = []
    if (!this.$v.item.cod.$dirty) return errors
    !this.$v.item.cod.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.cod.isOnlyNumber && errors.push(ONLY_NUMBER)
    return errors
  },

  institutionTypeErrors () {
    const errors = []
    if (!this.$v.item.type.$dirty) return errors
    !this.$v.item.type.required && errors.push('Tipo de estabelecimento é obrigatório.')
    return errors
  },

  institutionPercentageErrors () {
    const errors = []
    if (!this.$v.item.percentage.$dirty) return errors
    !this.$v.item.percentage.required && errors.push('Porcentagem é obrigatório.')
    !this.$v.item.percentage.numeric && errors.push('Porcentagem tem que ser numero decial.')
    return errors
  },


  institutionNameErrors () {
    const errors = []
    if (!this.$v.item.name.$dirty) return errors
    !this.$v.item.name.required && errors.push('Nome é obrigatório.')
    return errors
  },

  institutionEmailErrors () {
    const errors = []
    if (!this.$v.item.email.$dirty) return errors
    !this.$v.item.email.required && errors.push('Nome é obrigatório.')
    !this.$v.item.email.email && errors.push('Por favor, digitar um email válido.')
    return errors
  },

  institutionDocumentErrors () {
    const errors = []
    if (!this.$v.item.document.$dirty) return errors
    !this.$v.item.document.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.document.isValidCpfOrCnpj && errors.push(INVALID_FIELD)
    return errors
  },

  institutionAddressErrors () {
    const errors = []
    if (!this.$v.item.address.$dirty) return errors
    !this.$v.item.address.required && errors.push('Endereço é obrigatório.')
    return errors
  },

  institutionPhoneErrors () {
    const errors = []
    if (!this.$v.item.phone.$dirty) return errors
    !this.$v.item.phone.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.phone.minLength && errors.push(INVALID_FIELD)
    return errors
  },

  institutionCepErrors () {
    const errors = []
    if (!this.$v.item.cep.$dirty) return errors
    !this.$v.item.cep.required && errors.push('CEP é obrigatório.')
    !this.$v.item.cep.minLength && errors.push('Por favor, digitar um CEP válido.')
    return errors
  },

  institutionCityErrors () {
    const errors = []
    if (!this.$v.item.city.$dirty) return errors
    !this.$v.item.city.required && errors.push('Cidade é obrigatório.')
    return errors
  },

  institutionStateErrors () {
    const errors = []
    if (!this.$v.item.state.$dirty) return errors
    !this.$v.item.state.required && errors.push('Estado é obrigatório.')
    return errors
  },

  institutionNeighborhoodErrors () {
    const errors = []
    if (!this.$v.item.neighborhood.$dirty) return errors
    !this.$v.item.neighborhood.required && errors.push('Bairro é obrigatório.')
    return errors
  },

  institutionNumberErrors () {
    const errors = []
    if (!this.$v.item.number.$dirty) return errors
    !this.$v.item.number.required && errors.push('Número é obrigatório.')
    return errors
  },

  institutionRGErrors () {
    const errors = []
    if (!this.$v.item.rg.$dirty) return errors
    !this.$v.item.rg.required && errors.push('RG é obrigatório.')
    return errors
  },

  birthErrors () {
    const errors = []
    if (!this.$v.item.birth.$dirty) return errors
    !this.$v.item.birth.minLength && errors.push('Data inválida.')
    !this.$v.item.birth.required && errors.push('Data inválida.')
    return errors
  },
  

  bankErrors () {
    const errors = []
    if (!this.$v.item.birth.$dirty) return errors
    !this.$v.item.pix.bank.required && errors.push('Banco obrigatória.')
    return errors
  },

  keyCodeErrors () {
    const errors = []
    if (!this.$v.item.birth.$dirty) return errors
    !this.$v.item.pix.keyCode.key.validateKeyCode && errors.push('Chave inválida.')
    !this.$v.item.pix.keyCode.key.required && errors.push('Chave obrigatória.')
    return errors
  },

  keyCodeEmailErrors () {
    const errors = []
    if (!this.$v.item.birth.$dirty) return errors
    !this.$v.item.pix.keyCode.keyEmail.validateKeyCodeEmail && errors.push('Chave inválida.')
    !this.$v.item.pix.keyCode.keyEmail.required && errors.push('Chave obrigatória.')
    return errors
  },


  // // validações dados ats representante
  // responsibleNameErrors () {
  //   const errors = []
  //   if (!this.$v.item.responsible.name.$dirty) return errors
  //   !this.$v.item.responsible.name.required && errors.push('Nome é obrigatório.')
  //   return errors
  // },

  // responsibleCpfErrors () {
  //   const errors = []
  //   if (!this.$v.item.responsible.cpf.$dirty) return errors
  //   !this.$v.item.responsible.cpf.required && errors.push('Cpf é obrigatório.')
  //   return errors
  // },

  // responsibleAddressErrors () {
  //   const errors = []
  //   if (!this.$v.item.responsible.address.$dirty) return errors
  //   !this.$v.item.responsible.address.required && errors.push('Endereço é obrigatório.')
  //   return errors
  // },

  // responsiblePhoneErrors () {
  //   const errors = []
  //   if (!this.$v.item.responsible.phone.$dirty) return errors
  //   !this.$v.item.responsible.phone.required && errors.push('Telefone é obrigatório.')
  //   return errors
  // },

  // responsibleBusinessPhoneErrors () {
  //   const errors = []
  //   if (!this.$v.item.responsible.businessPhone.$dirty) return errors
  //   !this.$v.item.responsible.businessPhone.required && errors.push('Telefone Comercial é obrigatório.')
  //   return errors
  // },

  // responsibleCepErrors () {
  //   const errors = []
  //   if (!this.$v.item.responsible.cep.$dirty) return errors
  //   !this.$v.item.responsible.cep.required && errors.push('CEP é obrigatório.')
  //   !this.$v.item.responsible.cep.minLength && errors.push('Por favor, digitar um CEP válido.')
  //   return errors
  // },

  // responsibleCityErrors () {
  //   const errors = []
  //   if (!this.$v.item.responsible.city.$dirty) return errors
  //   !this.$v.item.responsible.city.required && errors.push('Cidade é obrigatório.')
  //   return errors
  // },

  // responsibleStateErrors () {
  //   const errors = []
  //   if (!this.$v.item.responsible.state.$dirty) return errors
  //   !this.$v.item.responsible.state.required && errors.push('Estado é obrigatório.')
  //   return errors
  // },

  // responsibleNeighborhoodErrors () {
  //   const errors = []
  //   if (!this.$v.item.responsible.neighborhood.$dirty) return errors
  //   !this.$v.item.responsible.neighborhood.required && errors.push('Estado é obrigatório.')
  //   return errors
  // },

  // responsibleNumberErrors () {
  //   const errors = []
  //   if (!this.$v.item.responsible.number.$dirty) return errors
  //   !this.$v.item.responsible.number.required && errors.push('Estado é obrigatório.')
  //   return errors
  // },

  // responsibleEmailErrors () {
  //   const errors = []
  //   if (!this.$v.item.responsible.email.$dirty) return errors
  //   !this.$v.item.responsible.email.required && errors.push('Email é obrigatório.')
  //   !this.$v.item.responsible.email.email && errors.push('Por favor, digitar um email válido.')
  //   return errors
  // },
}
