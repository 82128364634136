<template>
  <Modal
    :activeModal="activeModal"
    @closeModal="closeModal">
    <template v-slot:btnActivator>
      <v-btn
        tile
        :color="variables.colorPrimary"
        dark
        class="mb-2 ml-2 modal-cadastro-btn-cadastrar"
        @click="openModal"
        v-if="permiteAcao($route, 'add')"
        :loading="loadingBtnCadastro">Novo cadastro</v-btn>
    </template>
    <v-card v-if="loadingModal">
      <v-col class="d-flex justify-center align-center" style="min-height: 150px; width: 100%;">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-card>
    <v-card v-else>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        {{ isEdit ? `Editar Agência: ${item.cod} - ${item.name}` : `Cadastrar Nova Agência` }}
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <div class="abas" width="100%">
        <v-tabs
          v-model="tab"
          height="30px"
        >
          <v-tab href="#tab-1" class="abas-item" v-if="permiteAcao($route, 'add')">
            Proprietário
          </v-tab>

          <v-tab href="#tab-2" class="abas-item" v-if="permiteAcao($route, 'add')">
            Residência
          </v-tab>

          <v-tab href="#tab-3" class="abas-item" v-if="permiteAcao($route, 'add')">
            Dados Bancários
          </v-tab>

          <v-tab href="#tab-4" class="abas-item" v-if="permiteAcao($route, 'add')">
            Fotos
          </v-tab>

          <v-tab href="#tab-5" class="abas-item"  v-if="item.id" @click.prevent="requestsEntitiesProducts(item)">
            Config. Produto
          </v-tab>
        </v-tabs>
      </div>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item
          :key="1"
          :value="'tab-1'">
          <FormCadastroPessoais :touch="touchPessoias" @proximoCadastro="proximoCadastroEndereco" />
        </v-tab-item>

        <v-tab-item
          :key="2"
          :value="'tab-2'">
          <FormCadastroEnderecos :touch="touchEndereco" @proximoCadastro="proximoCadastroContasBancarias" />
        </v-tab-item>
        
        <v-tab-item
          :key="3"
          :value="'tab-3'">
          <FormCadastroContasBancarias @proximoCadastro="proximoCadastroFotos" />
        </v-tab-item>

        <v-tab-item
          :key="4"
          :value="'tab-4'">
          <FormCadastroFotos @concluirCadastro="concluir" :loadingBtn="loadingBtn" />
        </v-tab-item>

        <v-tab-item
          :key="5"
          :value="'tab-5'"
        >
          <Products 
            :entity="item"
            :loadingProdutos="loadingProdutos"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import Events from '@/core/service/events'
// eslint-disable-next-line
import { formatPhone } from '@/core/service/utils'
// import { isOnlyNumber, isValidCpfOrCnpj } from '@/utils/validationUtils'
// import { validCnpj } from '@/core/service/utils'
// import { cnpj } from 'cpf-cnpj-validator'
import variables from '@/assets/styles/helpers/_variables.scss'
import axios from 'axios'
import config from '@/core/config'
// eslint-disable-next-line
import { merge, size, map, each, find } from 'lodash'
// eslint-disable-next-line
import { isOnlyNumber, isValidCpfOrCnpj, isValidPhone, validateDDD } from '@/utils/validationUtils'
import moment from 'moment'
import { errorSnackbar } from '@/core/service/utils'

import validacao from './validacao'
// import searchUfCity from '@/views/components/searchUfCity'


// Componentes
import FormCadastroPessoais from './formCadastroPessoais'
import FormCadastroEnderecos from './formCadastroEnderecos'
import FormCadastroContasBancarias from './formCadastroContasBancarias'
import FormCadastroFotos from './formCadastroFotos'
import Modal from '@/views/components/modal'
import Products from './products'


/**
 * Mixins
 */
// import autoComplete from '@/views/cadastros/entities/mixins/autoComplete'
import modalCadastro from '@/views/cadastros/entities/mixins/modalCadastro'

export default {
  name: 'ModalCadastroEstabelecimento',
  mixins: [validationMixin, modalCadastro],
  directives: {mask},
  components: {
    Products,
    Modal,
    FormCadastroPessoais,
    FormCadastroEnderecos,
    FormCadastroContasBancarias,
    FormCadastroFotos
  },
  data: () => ({
    menuDate: false,
    dtBirthString: '',
    activeModal: false,
    loading: false,
    tab: 'tab-1',
    cepValidate: false,
    cepValidateResponsible: false,
    loadingModal: false,
    loadingProdutos: false,
    listaTipoEstabelecimento: [
      { text: '1 - Grande', value: 1 },
      { text: '2 - Médio', value: 2 },
      { text: '3 - Pequeno', value: 3 }
    ],
    listaEstadosCivies: [
      { text: 'Solteiro (a)', value: 'Solteiro (a)' },
      { text: 'Casado (a)', value: 'Casado (a)' },
      { text: 'Divorciado (a)', value: 'Divorciado (a)' },
      { text: 'Viúvo (a)', value: 'Viúvo (a)' },
    ],
    dados: {
      pessoais: {}
    },

    touchPessoias: false,
    touchEndereco: false,
    loadingBtn: false
  }),
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('estabelecimento', {
      item: 'item',
      listaItensEstabelecimento: 'listaItensSelect',
      listaItensCredenciador: 'listaItensSelectCredenciador'
    }),
    ...validacao,

    variables: () => variables,
    isEdit () {
      return this.item.id ? !!1 : !!0
    }
  },

  mounted () {
    Events.$on('cadastro::openModalCadastro2', () => {
      this.openModal({})

      if (!this.permiteAcao(this.$route, 'add')) {
        this.tab = 'tab-5'
        this.requestsEntitiesProducts(this.item)
      }
    })

    this.getBankList()
  },

  watch: {
    'item.birth' (val) {
      if (val) {
        this.dtBirthString = moment(val).format('DD/MM/YYYY')
      }
    }
  },
  methods: {
    ...mapActions('estabelecimento', ['cadastrarItem', 'limparItem', 'getItens', 'editarItem', 'obterItensSelect', 'obterItensSelectProducts', 'obterEntityProduct', 'obterItensSelect', 'getItem', 'getBankList']),
    ...mapActions('estabelecimentoComissao', {
      obterItensSelectParentId: 'obterItensSelect',
      clearDadosComissaoCredenciador: 'clearDados'
    }),
    // openModal () {
    //   this.activeModal = true
    // },

    // openModal () {
    //   this.activeModal = true
    // },

    mergeItens (estabelecimento, credenciador) {
      return merge(estabelecimento, credenciador)
    },

    requestsEntitiesProducts (entity) {
      this.obterItensSelectProducts()
      this.loadingProdutos = true
      this.obterEntityProduct({ entityId: entity.id })
        .then(() => this.tab = 'tab-5')
        .catch(err => {
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
        .finally(() => this.loadingProdutos = false)
      this.obterItensSelectParentId({ id: entity.parentId })
    },

    closeModal () {
      this.activeModal = false
      this.limparItem()
      this.clearDadosComissaoCredenciador()
      Events.$emit('cadastro::closeModal')
      this.tab = 'tab-1'
      this.$v.item.$reset()
      this.dados.validacaoPessoais ? this.dados.validacaoPessoais.$reset() : ''
      this.dados.validacaoEnderecos ? this.dados.validacaoEnderecos.$reset() : ''
      this.dados.validacaoAccounts ? this.dados.validacaoAccounts.$reset() : ''
      Events.$emit('closeModal::modalCadastro')
      this.cepValidate = false,
      this.cepValidateResponsible = false
      this.dtBirthString = ''
      
    },

    async buscarCep () {
      if(/^[0-9]{5}-[0-9]{3}$/.test(this.item.cep)){
        const result = await axios.get(config.apiViaCep + this.item.cep + "/json/")
        if (result.data.erro) {
          this.cepValidate = false
          return
        }

        this.cepValidate = true
        this.item.address = result.data.logradouro
        this.item.state = result.data.uf
        this.item.city = result.data.localidade
        this.item.neighborhood = result.data.bairro
      }
    },

    async buscarCepResponsible () {
      if(/^[0-9]{5}-[0-9]{3}$/.test(this.item.responsible.cep)){
        const result = await axios.get(config.apiViaCep + this.item.responsible.cep + "/json/")
        if (result.data.erro) {
          this.cepValidateResponsible = false
          return
        }

        this.cepValidateResponsible = true
        this.item.responsible.address = result.data.logradouro
        this.item.responsible.state = result.data.uf
        this.item.responsible.city = result.data.localidade
        this.item.responsible.neighborhood = result.data.bairro
      }
    },

    testeDate (dtString) {
      const testeDate =  moment(dtString, 'DD/MM/YYYY', true)
      if (testeDate.isValid()) {
        this.item.birth = moment(dtString, 'DD/MM/YYYY').format('YYYY-MM-DD')
      } else {
        this.item.birth = ''
      }
    },

    closeModalDatePicker (date) {
      this.dtBirthString = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
      this.menuDate = false
    },

    submit () {
      // if (this.$v.item.$invalid) {
      //   this.$v.item.$touch()
      //   document.querySelector('.v-dialog').scroll({ top: 0, left: 0, behavior: 'smooth' })
      //   return false
      // }
      if (!this.item.id && (!this.dados.validacaoPessoais || this.dados.validacaoPessoais.$invalid)) {
        this.tab = 'tab-1'
        if (this.dados.validacaoPessoais) this.dados.validacaoPessoais.$touch()
        this.touchPessoias = true
        return
      }

      if (!this.item.id && (!this.dados.validacaoEnderecos || this.dados.validacaoEnderecos.$invalid)) {
        this.tab = 'tab-2'
        if (this.dados.validacaoEnderecos) this.dados.validacaoEnderecos.$touch()
        this.touchEndereco = true
        return
      }
       if (!isValidCpfOrCnpj(this.item.document.replace(/\D/g, ''))) {
         this.$swal({
            icon: 'warning',
            text: `CPF/CNPJ Inválido!`,
            showCancelButton: false,
            showConfirmButton: false,
          }).then(() => {
            this.tab = 'tab-1'
          })

          return
       }

       if (!isValidPhone(this.item.phone) || !validateDDD(this.item.phone)) {
         this.$swal({
            icon: 'warning',
            text: `Telefone Inválido!`,
            showCancelButton: false,
            showConfirmButton: false,
          }).then(() => {
            this.tab = 'tab-1'
          })

          return
       }

      // if (!this.dados.validacaoAccounts || this.dados.validacaoAccounts.$invalid) {
      //   this.tab = 'tab-3'
      //   if (this.dados.validacaoAccounts) this.dados.validacaoAccounts.$touch()
      //   return
      // }
      
      const addressFormat = each(map(this.item.adresses), it => {
        it.cep = it.cep.replace(/\D/g, '')
      })

      const adressInvalid = find(this.item.adresses, it => !it.cep || !it.address || !it.neighborhood || !it.state || !it.city)
      if (adressInvalid) {
        this.$swal({
          icon: 'warning',
          text: `Dados de endereços inválidos`,
          showCancelButton: false,
          showConfirmButton: false,
        }).then(() => {
          this.tab = 'tab-2'
        })

        return
      }

      if (addressFormat.filter(it => it.typeAddress === 'commercial').length === 0) {
          this.$swal({
            icon: 'warning',
            text: `Por favor, cadastrar pelo menos um endereço comercial`,
            showCancelButton: false,
            showConfirmButton: false,
          }).then(() => {
            this.tab = 'tab-2'
          })

          return
      }

      const accountsFormat = each(map(this.item.accounts), it => {
        it.account = it.account.replace(/\D/g, '')
        it.agency = it.agency.replace(/\D/g, '')
      })

      this.loadingBtn = true

      // if (this.item.maritalStatus !== 'married') {
      //   this.item.nameSpouse = ''
      //   this.item.birthSpouse = ''
      // }

      const dados = {
        parentId: this.item.parentId || null,
        jsonData: {
          cod: this.item.cod,
          name: this.item.name,
          email: this.item.email.toLowerCase(),
          cnpj: size(this.item.document.replace(/\D/g, '')) > 11 ? this.item.document.replace(/\D/g, '') : null,
          cpf: size(this.item.document.replace(/\D/g, '')) === 11 ? this.item.document.replace(/\D/g, '') : null,
          rg: this.item.rg ? this.item.rg.replace(/\D/g, '') : undefined,
          birth: this.item.birth,
          gender: this.item.gender,
          maritalStatus: this.item.maritalStatus,
          nameSpouse: this.item.nameSpouse,
          birthSpouse: this.item.birthSpouse,
          phone: formatPhone(this.item.phone),
          familyIncome: this.item.familyIncome,
          adresses: Object.values(addressFormat),
          accounts: Object.values(accountsFormat),
          pix: {
            bank: {
              code:  this.item.pix.bank.code,
              name:  this.item.pix.bank.name,
            },
            keyCode: {
              key: this.item.pix.keyCode.key.replace(/\D/g, '') || this.item.pix.keyCode.keyEmail,
              type: this.item.pix.typeKey
            },
            typeKey: this.item.pix.typeKey || ''
          },

          children:  size(this.item.children) ? Object.values(this.item.children) : [],
          photos: Object.values(this.item.photos),
          fotosDeletadas: Object.values(this.item.fotosDeletadas),
          lat: this.item.lat ? this.item.lat.replace(/[,]/, '.') : 0,
          lng: this.item.lng ? this.item.lng.replace(/[,]/, '.') : 0,
        },
        jsonFlags: this.item.jsonFlags
      }



      if (this.item.id) {
        dados.id = this.item.id
        this.loadingBtn = true
        this.editarItem(dados).then(() => {
          this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          this.closeModal()
          this.loading = false
          this.loadingBtn = false
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Edição realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          this.loading = false
          this.loadingBtn = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      } else {
        

        // if (!this.dados.validacaoAccounts || this.dados.validacaoAccounts.$invalid) {
        //   this.tab = 'tab-3'
        //   if (this.dados.validacaoAccounts) this.dados.validacaoAccounts.$touch()
        //   return
        // }

        this.loadingBtn = true

        this.cadastrarItem(dados).then((result) => {
          this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          this.item.id = result.data.id
          this.requestsEntitiesProducts(result.data)
          this.loading = false
          this.loadingBtn = false
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Cadastro realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          this.loading = false
          this.loadingBtn = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      }

    },

    proximoCadastroEndereco(e) {
      this.dados.validacaoPessoais = e.validacao
      this.tab = 'tab-2'
    },
    
    proximoCadastroContasBancarias(e) {
      this.dados.validacaoEnderecos = e.validacao
      this.tab = 'tab-3'
    },

    proximoCadastroFotos(e) {
      console.log(e)
      // this.dados.validacaoAccounts = e.validacao
      this.tab = 'tab-4'
    },

    concluir () {
      this.submit()
    },

    async openModal (item) {
      this.activeModal = true

      this.loadingModal = true

      await Promise.all([
        this.obterItensSelect({ pageSize: this.$store.getters.ItensPaginacao }),
      ])
      .then(() => {
        if (item.id) {
          this.dadosItem = item
        }
      })
      .catch((err) => {
        errorSnackbar(err.error)
      })
      .finally(() =>  this.loadingModal = false)
    },
  },

  validations () {
    return  {
      item: {
        parentId: { required },
        cod: { required, isOnlyNumber },
        type: { required },
        email: { required, email },
        name: { required },
        document: { required, isValidCpfOrCnpj },
        address: { required },
        cep: { required, minLength: minLength(9) },
        phone: { required, minLength: minLength(14), maxLength: maxLength(15) },
        birth: { required, minLength: minLength(10)},
        city: { required },
        state: { required },
        neighborhood: { required },
        number: { required }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './style.scss';
  .modal-cadastro-form-col-imput .v-text-field--outlined.error--text::v-deep {
    .v-input__slot {
      margin-bottom: 20px !important;
    }
    .v-text-field__details {
      bottom: 5px;
    }
  }

  .modal-cadastro2 {
    .br-btn {
      width: 100%;
      max-width: 250px;
      margin: 0 !important;

      &-cancelar {
        margin-bottom: 10px !important;
      }
    }

    > .v-card .modal-cadastro-footer {
      flex-direction: column;
    }
  }
</style>