<template>
  <v-form class="box-form">
    <v-card-text class="modal-cadastro-form">
        <v-row >
          <v-col cols="12" sm="6" v-for="(it, key, index) in item.photos" :key="index" class="mb-2">
            <v-row>
              <v-col cols="10" class="modal-cadastro-form-col-imput">
                <v-text-field
                  v-model="it.description"
                  outlined
                  clearable
                  label="Descrição"
                  type="text"
                  dense
                  :hint="it.nameImage"
                  persistent-hint
                >
                  <template v-slot:append>
                    <v-file-input
                      accept="image/*"
                      label="File input"
                      filled
                      outlined
                      dense
                      hide-input
                      prepend-icon="mdi-camera"
                      class="mt-n2"
                      @change="addImage(it, $event)"
                    />
                  </template>
                </v-text-field>
              </v-col>

              <v-col dense cols="2" class="modal-cadastro-form-col-imput pl-0">
                <v-btn width="25" height="25" fab dark small color="red" class="fab-remove-item mt-1" @click="removeItem(key)">
                  <v-icon dark small>mdi-minus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
    </v-card-text>

    <v-sheet
      class="mx-auto pl-0 pr-0"
      elevation="0"
      max-width="800"
    >
      <v-slide-group
        v-model="modelSlide"
        class="pl-0 pr-0 pb-4"
        center-active
        show-arrows
        :key="resizes1+'A'"
      >
        <v-slide-item
          v-for="(it, key, index) in item.photos"
          :key="index"
          v-slot="{ active, toggle }"
        >
          <v-card
            class="white--text align-end ma-1"
            :color="active ? 'primary' : 'grey lighten-1'"
            width="100"
            @click="openModalImagem(it)"
          >
            <v-img
              height="150"
              width="100"
              :src="it.image" aspect-ratio="1"
            />
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>

    <v-card-actions class="modal-cadastro-footer">
      <v-btn 
        :color="variables.colorPrimary"
        @click="addItem"
        class="br-btn br-btn-cancelar">Adicionar outra foto</v-btn>
      <v-btn
        @click="concluir"
        :color="variables.colorPrimary"
        :loading="loadingBtn"
        class="br-btn">Concluir cadastro</v-btn>
    </v-card-actions>


    
    <ModalFoto
      :isActiveModal="isOpenModalFoto"
      :data="dataAnexo"
      @closeModal="closeModalFoto"
    />
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
// import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
// import Events from '@/core/service/events'
// import { isOnlyNumber, isValidCpfOrCnpj } from '@/utils/validationUtils'
// import { validCnpj } from '@/core/service/utils'
// import { cnpj } from 'cpf-cnpj-validator'
import variables from '@/assets/styles/helpers/_variables.scss'
import { size, find } from 'lodash'
//eslint-disable-next-line
import { resizeBase64ForMaxWidth } from '@/core/service/resizeBase64'
// import moment from 'moment'
// import { errorSnackbar } from '@/core/service/utils'

import validacao from './validacao'

import ModalFoto from './modalFoto'

/**
 * Mixins
 */
// import autoComplete from '@/views/cadastros/entities/mixins/autoComplete'
import modalCadastro from '@/views/cadastros/entities/mixins/modalCadastro'

export default {
  name: 'ModalCadastroEstabelecimento',
  mixins: [validationMixin, modalCadastro],
  directives: {mask},
  props: {
    loadingBtn: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ModalFoto
  },
  data: () => ({
    menuDate: false,
    menuDateSpouse: false,
    dtBirthString: '',
    dtCadastroString: '',
    dtSpouseString: '',
    activeModal: false,
    loading: false,
    isOpenModalFoto: false,
    dataAnexo: {},
    modelSlide: null,
    resizes1: 0
  }),
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('estabelecimento', {
      item: 'item',
      listaItensEstabelecimento: 'listaItensSelect',
      listaItensCredenciador: 'listaItensSelectCredenciador',
      qtdItemForm: 'qtdItemForm'
    }),
    ...validacao,

    variables: () => variables,
  },

  methods: {
    ...mapActions('estabelecimento', ['cadastrarItem', 'limparItem', 'getItens', 'editarItem', 'obterItensSelect', 'obterItensSelectProducts', 'obterEntityProduct', 'obterItensSelect', 'getItem', 'setQtdItemForm']),
    ...mapActions('estabelecimentoComissao', {
      obterItensSelectParentId: 'obterItensSelect',
      clearDadosComissaoCredenciador: 'clearDados'
    }),

    exibir() {
      return size(this.item.photos) > 1 ? true : false
    },

    removeItem(item) {
      const foto = find(this.item.photos, (it, idx) => item === idx)
      this.item.fotosDeletadas.push(foto.originalImage)
      this.$delete(this.item.photos, item)
    },

    addItem () {
      if (size(this.item.photos) > 9) {
        return
      }
      this.setQtdItemForm()
      this.$set(this.item.photos, `item${this.qtdItemForm}`, {
        description: '',
        image: '',
        nameImage: '',
        isRegister: false
      })
    },

    addImage (item, e) {
      if (item.isRegister) {
        this.item.fotosDeletadas.push(item.originalImage)
      }

      if (!e) {
        item.image = ''
        return
      }
      if (!e.type.match('image.*')) {
        item.image = ''
        return
      }
      // const img = new Image(),
      item.nameImage = e.name
      item.isRegister = false
      item.type = 'base64'
      const reader = new FileReader();
      reader.onload = (e) => {
        let base64Image_320x240 = e.target.result
        let maxWidth = 600;
        let maxHeight = 600;
        
        let successCallback = function(resizedImage) {
          item.image = resizedImage
        };
        
        let errorCallback = function(errorMessage) {
          alert(errorMessage);
        };
        
        resizeBase64ForMaxWidth(base64Image_320x240, maxWidth, maxHeight, successCallback, errorCallback);
        // item.image = resizeBase64(e.target.result, 700, 700)
      }
      reader.readAsDataURL(e);
      this.resizes1++
    },

    concluir () {
      this.$emit('concluirCadastro',{})
    },

    openModalImagem (image) {
      this.dataAnexo = image
      this.isOpenModalFoto = true
      console.log(image)
    },

    closeModalFoto () {
      this.dataAnexo = {}
      this.isOpenModalFoto = false
    }
  },

  validations () {
    return  {
      item: {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './style.scss';
  .modal-cadastro-form-col-imput .v-text-field--outlined.error--text::v-deep {
    .v-input__slot {
      margin-bottom: 20px !important;
    }
    .v-text-field__details {
      bottom: 5px;
    }
  }

  .modal-cadastro2 {
    .br-btn {
      width: 100%;
      max-width: 250px;
      margin: 0 !important;

      &-cancelar {
        margin-bottom: 10px !important;
      }
    }

    > .v-card .modal-cadastro-footer {
      flex-direction: column;
    }
  }
</style>