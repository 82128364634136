<template>
  <BrToolBar 
    @search="buscar"
    :configFilter="configFilter"
    :divHidden="false"
    :labelTextField="labelTextField">
    <template v-slot:btnSelect>
      <v-col cols="12" sm="4" md="3">
        <slot />
      </v-col>
    </template>
    <!-- <BrModalCadastro /> -->
    <BrModalCadastroLegal />
  </BrToolBar>
</template>

<script>
/**
 * MIXIN
 */
import toolbar from '@/views/mixins/toolbar'
// import BrModalCadastro from './modalCadastro'
import BrModalCadastroLegal from './modalCadastroLegal'

export default {
  name: `BrToolBarCadastroRole`,
  mixins: [toolbar],
  components: { 
    // BrModalCadastro,
    BrModalCadastroLegal,
    BrToolBar: () => import('@/views/components/toolbar/toolbar2')
  }
}
</script>