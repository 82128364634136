<template>
  <v-form class="box-form">
     <v-card-text class="modal-cadastro-form pb-0">
      <v-row>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-currency-field
            v-model="item.familyIncome"
            label="Renda Familiar"
            prefix="R$"
            outlined
            dense
            :error-messages="rendaFamiliarError"
          />
        </v-col>
      </v-row>

      <v-row v-for="(it, key, index) in item.accounts" :key="index">
        <v-col cols="12">
          <v-card-title>
            Conta Bancárias

            <v-btn
              fab dark 
              color="red" 
              class="fab-remove-item ml-1"
              @click="removeItem(key)"
              v-if="exibirBtnRemove()"
            >
              <v-icon dark size="10">mdi-minus</v-icon>
            </v-btn>
          </v-card-title>
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-select
              :items="listaTiposConta"
              v-model="it.typeAccount"
              dense
              outlined
              label="Tipo de conta:"
              :error-messages="errorMensagens(key, 'typeAccount')"
            />
          </v-col>
          
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-autocomplete
            v-model="it.bank"
            :items="listBanks"
            class="autocomplete"
            label="Banco"
            return-object
            item-text="text"
            dense
            outlined
            item-value="code"
            :error-messages="errorMensagens(key, 'bank')"
          />
        </v-col>
        <v-col cols="6" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field 
            type="tel"
            v-model="it.agency"
            label="Agência:"
            outlined
            dense 
            :error-messages="errorMensagens(key, 'agency')"
            v-mask="['###', '####', '###-##']"
          />
        </v-col>
        <v-col cols="6" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field 
            type="tel"
            v-model="it.account"
            label="Conta:"
            outlined
            dense
            v-mask="['##############']"
            :error-messages="errorMensagens(key, 'account')"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text class="modal-cadastro-form pb-0">
      <v-switch
        v-model="item.jsonFlags.payerOfPrizesByPix"
        inset
        dense
        :label="'Aceita fazer pagamentos via PIX'"
        class="ma-0 pb-0"
        :hide-details="true"
      />
    </v-card-text>

    <v-card-text class="modal-cadastro-form pt-0" v-if="item.jsonFlags.payerOfPrizesByPix">
      <v-col cols="12" class="pl-0 pt-0">
        <v-card-title>
          Chave PIX
        </v-card-title>
      </v-col>
      <v-row>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="5">
          <v-autocomplete
            v-model="item.pix.bank"
            :items="listBanks"
            class="autocomplete"
            label="Banco"
            return-object
            item-text="text"
            item-value="code"
            outlined
            dense
          />
        </v-col>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="7">
          <v-row class="align-end">
            <v-col cols="4" class="py-0 pr-1">
              <v-select
                :items="listaItensSelect"
                v-model="item.pix.typeKey"
                dense
                solo
                class="mr-0"
              />
            </v-col>
            <v-col cols="8" class="py-0">
              <v-text-field  
                v-model="item.pix.keyCode.keyEmail"
                label="Chave"
                outlined
                dense
                v-show="item.pix.typeKey === 'e-mail'"
              />
              
              <v-text-field
                v-show="item.pix.typeKey !== 'e-mail'"
                v-model="item.pix.keyCode.key" 
                v-mask="getMask()"
                label="Chave"
                outlined
                dense
                type="tel"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="modal-cadastro-footer">
      <v-btn 
        :color="variables.colorPrimary"
        @click="addItem"
        class="br-btn br-btn-cancelar">Adicionar outra conta</v-btn>
      <v-btn
        @click="proximaEtapa"
        :color="variables.colorPrimary"
        class="br-btn">Avançar</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
// eslint-disable-next-line
import { required, email, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
// import Events from '@/core/service/events'
import { isValidCpfOrCnpj, isValidPhone, isValidEmail } from '@/utils/validationUtils'
// import { validCnpj } from '@/core/service/utils'
// import { cnpj } from 'cpf-cnpj-validator'
import variables from '@/assets/styles/helpers/_variables.scss'
// eslint-disable-next-line
import { merge, size, flatMap } from 'lodash'
// import moment from 'moment'
// import { errorSnackbar } from '@/core/service/utils'

import validacao from './validacao'

/**
 * Mixins
 */
// import autoComplete from '@/views/cadastros/entities/mixins/autoComplete'
import modalCadastro from '@/views/cadastros/entities/mixins/modalCadastro'

const validateKeyCode = (bank, keyCodeEmail, typeKey) => value => {
  if (!bank || keyCodeEmail) return true

  switch (typeKey) {
    case 'celular':
      return isValidPhone(value)
    case 'CPF/CNPJ':
      return isValidCpfOrCnpj(value)
    default:
      return false
  }
}

const validateKeyCodeEmail = (bank, keyCode, typeKey) => value => {
  if (!bank || keyCode) return true

  switch (typeKey) {
    case 'e-mail':
      return isValidEmail(value)
    default:
      return false
  }
}

export default {
  name: 'ModalCadastroEstabelecimento',
  mixins: [validationMixin, modalCadastro],
  directives: {mask},
  components: { 
  },
  data: () => ({
    menuDate: false,
    menuDateSpouse: false,
    dtBirthString: '',
    dtCadastroString: '',
    dtSpouseString: '',
    activeModal: false,
    loading: false,
    qtdItemForm: 1,
    listaEstadosCivies: [
      { text: 'Solteiro (a)', value: 'Solteiro (a)' },
      { text: 'Casado (a)', value: 'Casado (a)' },
      { text: 'Divorciado (a)', value: 'Divorciado (a)' },
      { text: 'Viúvo (a)', value: 'Viúvo (a)' },
    ],
    listaTiposConta: [
      { text: 'Conta Corrente', value: 'CC' },
      { text: 'Conta Poupança', value: 'CP' }
    ],
  }),

   mounted () {
    // Events.$on('cadastro::closeModal', () => {
    //   this.rendaFamiliar = 0,
    //   this.form = {
    //     item1: {
    //       typeAccount: '',
    //       bank: '',
    //       agency: '',
    //       account: ''
    //     }
    //   }

    //   this.pix = {
    //     bank: '',
    //     typeKey: 'e-mail',
    //     keyCode: {
    //       key: '',
    //       keyEmail: ''
    //     }
    //   }
    // })


    this.getBankList()

    this.rendaFamiliar = this.item.familyIncome
    this.qtdItemForm = size(this.item.accounts)

    // if (this.item.id) {
    //   this.item.accounts.forEach((it, idx) => {
    //     this.form[`item${idx+1}`] = {
    //       typeAccount: it.typeAccount,
    //       bank: it.bank,
    //       agency: it.agency,
    //       account: it.account
    //     }
    //   })

    //   if (this.item.pix && this.item.pix.bank) {
    //     this.pix = {
    //       bank: this.item.pix.bank,
    //       keyCode: this.item.pix.keyCode,
    //       typeKey: this.item.pix.typeKey
    //     }
    //   }
    // }
  },

  watch: {
    'pix.typeKey' (value) {
      if (value === 'e-mail') {
        this.pix.keyCode.key = ''
        return
      }

      this.pix.keyCode.keyEmail = ''
    }
  },

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('estabelecimento', {
      item: 'item',
      listaItensEstabelecimento: 'listaItensSelect',
      listaItensCredenciador: 'listaItensSelectCredenciador',
      listBanks: 'listBanks'
    }),
    ...validacao,

    variables: () => variables,

    rendaFamiliarError () {
      return []
      // const errors = []
      // if (!this.$v.item.rendaFamiliar.$dirty) return errors
      // !this.$v.item.rendaFamiliar.required && errors.push('Campo Obrigatório')
      // return errors
    },

    listaItensSelect: () => ['e-mail', 'celular', 'CPF/CNPJ']
  },

  methods: {
    ...mapActions('estabelecimento', ['cadastrarItem', 'limparItem', 'getItens', 'editarItem', 'obterItensSelect', 'obterItensSelectProducts', 'obterEntityProduct', 'obterItensSelect', 'getItem', 'getBankList']),
    ...mapActions('estabelecimentoComissao', {
      obterItensSelectParentId: 'obterItensSelect',
      clearDadosComissaoCredenciador: 'clearDados'
    }),
    // openModal () {
    //   this.activeModal = true
    // },

    openModal () {
      this.activeModal = true
    },


    removeItem(item) {
      if (size(this.item.accounts) === 1) {
        return
      }
      this.$delete(this.item.accounts, item)
    },

    exibirBtnRemove () {
      return !(size(this.item.accounts) === 1)
    },


    addItem () {
      this.qtdItemForm++
      this.$set(this.item.accounts, `item${this.qtdItemForm}`,
        {
          bank: '',
          agency: '',
          account: ''
        }
      )
    },

    errorMensagens (index, item) {
      console.log(index, item)
      return []
      // let error = []
      // if (!this.$v.item.accounts[index]) return []
      // if (!this.$v.item.accounts[index][item].$dirty) return []

      // !this.$v.item.accounts[index][item].required && error.push('Campo Obrigatório')
      // return error
    },

    proximaEtapa () {
      // if (this.$v.item.accounts.$invalid) {
      //   this.$v.item.accounts.$touch()
      //   document.querySelector('.v-dialog').scroll({ top: 0, left: 0, behavior: 'smooth' })
      //   return false
      // }
      

      this.$emit('proximoCadastro', {})
    },

    getMask () {
      if (this.item.pix.typeKey === 'e-mail') return ''
      if (this.item.pix.typeKey === 'CPF/CNPJ') return ['###.###.###-##', '##.###.###/####-##']
      if (this.item.pix.typeKey === 'celular') return '(##) # ####-####'
    }
  },

  validations () {
    let items = {}
    let obj = {}
    obj = flatMap(this.item.accounts, (v, k) => {
      return { 
        [k]: {
          typeAccount: { required },
          bank: { required },
          agency: { required },
          account: { required }
        }
      }
    })
    obj.forEach(v => {
      items[Object.keys(v)[0]] = v[Object.keys(v)[0]]
    })

    const pix = {
      bank: {
        required: requiredIf(() => this.item.pix.keyCode.key || this.item.pix.keyCode.keyEmail)
      },
      keyCode: {
        key: {
          validateKeyCode: validateKeyCode(this.item.pix.bank.code, this.item.pix.keyCode.keyEmail, this.item.typeKey),
          required: requiredIf(() => this.item.pix.bank.code && !this.item.pix.keyCode.keyEmail),
        },
        keyEmail: {
          validateKeyCode: validateKeyCodeEmail(this.item.pix.bank.code, this.item.pix.keyCode.key, this.item.typeKey),
          required: requiredIf(() => this.item.pix.bank.code && !this.item.pix.keyCode.key),
        }
      },
    }

    // return { form:  items, rendaFamiliar: { required }, pix }

    return { 
      item: {
        // accounts:  items,
        // rendaFamiliar: { required },
        pix
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './style.scss';
  .modal-cadastro-form-col-imput .v-text-field--outlined.error--text::v-deep {
    .v-input__slot {
      margin-bottom: 20px !important;
    }
    .v-text-field__details {
      bottom: 5px;
    }
  }

  .modal-cadastro2 {
    .br-btn {
      width: 100%;
      max-width: 250px;
      margin: 0 !important;

      &-cancelar {
        margin-bottom: 10px !important;
      }
    }

    > .v-card .modal-cadastro-footer {
      flex-direction: column;
    }
  }

  .fab-remove-item {
    width: 20px !important;
    height: 20px !important;
    min-height: 20px !important;
    min-width: 20px !important;
  }

</style>